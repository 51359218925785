<template>
  <b-modal
    id="view-slip-modal"
    ref="view-slip-modal"
    title="รูปสลิป"
    hide-footer
    centered
  >
    <div class="text-center p-2 px-3">
      <b-img-lazy
        :src="require('@/assets/images/banner/banner-27.jpg')"
        fluid
        class="w-100') mb-2"
      />
      <div class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click.prevent="hideModal"
        >
          ปิด
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'


export default {
  name: 'ViewSlipModal',
  props: {
    paymentId: {
      type: String,
      default: ''
    }
  },
  computed: {
     ...mapState({
      isLoading: (state) => state.payment.isGettingPaymentSlip,
    }),
    ...mapGetters(['paymentSlip']),
  },
  watch: {
    paymentSlip(val) {
      console.log('paymentSlip',val)
    },
    paymentId(val) {
      if (val) {
        this.getPaymentSlip(val)
      }
    },
  },
  methods: {
    ...mapActions(['getPaymentSlip']),
    hideModal() {
      this.$bvModal.hide('view-slip-modal')
    },
    onClickConfirm() {
    },
  },
  
}
</script>
